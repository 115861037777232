/** @jsx jsx */
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { SendError } from "../../services/error-log-service";
import { Button, jsx, Text } from 'theme-ui';
import * as Yup from "yup";
import { checkMaximumLength, checkMinimumLength } from "../../helper/string-helper";
import { getJwtTokenIdentifier } from "../../services/auth-service";
import { GetPersonDetails, UpdatePersonDetails } from "../../services/person-service";
import { DeleteAccount } from "../../services/user-service";
import { countryOptions } from "../../services/marketing-service";
import Select from 'react-select';

const UpdateUser: React.FC<any> = (props: any) => {
  return (
    <div className="container" sx={styles.container}>
      <div sx={styles.wrapper} className="col-md-8">
        <UserDetailsForm props={props} />
        <hr className="bar-indent-center" />
        <DeleteAccountForm props={props} />
      </div>
    </div>
  );
};

const UserDetailsForm = ({ props }: any) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    surname: Yup.string().required('Last name is required'),
    address: Yup.string().required('Address is required'),
    townCity: Yup.string().required('Town or city is required'),
    county: Yup.string().required('County is required'),
    postalCode: Yup.string().required('Postcode is required'),
    phone: Yup.string()
      .required('Phone number is required')
      .test('length', 'Phone number has a minimum of 8 characters', x => checkMinimumLength(x, 8))
      .test('length', 'Phone number has a maximim of 15 characters', x => checkMaximumLength(x, 15))
  })

  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([{ name: "" }]);
  const [addresses, setAddressess] = useState([{ addressLine1: "", city: "", county: "", postalCode: "", country: "" }]);

  const [messages, setMessages] = useState<string[]>([]);
  const [error, setError] = useState(true);
  const [countryValue, setCountryValue] = useState("");

  useEffect(() => {
    GetUserData();
  }, []);

  const GetUserData = async () => {
    await GetPersonDetails(props.id).then(({ data }) => {
      if (data !== undefined) {
        setData(data);
        if (data?.companies?.length > 0) {
          setCompanies(data.companies);
        }

        if (data?.addresses?.length > 0) {
          setAddressess(data.addresses.filter((x: { companyId: null; }) => x.companyId == null));
          setCountryValue(addresses.length > 0 ? addresses[0].country : "")
        }
      }
    })
  }
  const [loading, setLoading] = useState(false);

  const getCountryValues = () => {
    if (countryValue) {
      const options = countryOptions(false);
      return options.filter(x => x.label === countryValue);
    }
  }


  const countryChange = (selected: any, formik: any) => {
    formik.setFieldValue("country", selected);
    setCountryValue(selected);
  };

  return (
    <Formik
      initialValues={{
        firstName: data.firstName ? data.firstName : "",
        surname: data.surname ? data.surname : "",
        company: companies.length > 0 ? companies[0].name : "",
        address: addresses.length > 0 ? addresses[0].addressLine1 : "",
        townCity: addresses.length > 0 ? addresses[0].city : "",
        county: addresses.length > 0 ? addresses[0].county : "",
        postalCode: addresses.length > 0 ? addresses[0].postalCode : "",
        country: addresses.length > 0 ? addresses[0].country : "",
        phone: data.phone ? data.phone : ""
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        setLoading(true);

        if (data === undefined) {
          setLoading(false);
          setError(true);
          const result: string[] = ["Something went wrong, please try again."];
          setMessages(result)
        }

        if (data.addresses != null && data.addresses.length > 0) {
          data.addresses[0].addressLine1 = values.address;
          data.addresses[0].postalCode = values.postalCode;
          data.addresses[0].city = values.townCity;
          data.addresses[0].county = values.county;
          data.addresses[0].country = values.country;
        }
        else {
          data.addresses.push({
            addressLine1: values.address,
            postalCode: values.postalCode,
            city: values.townCity,
            county: values.county,
            country: values.country
          })
        }

        if (data.companies != null && data.companies.length > 0) {
          data.companies[0].name = values.company;
        }
        else {
          data.companies.push({
            name: values.company,
          })
        }

        data.firstName = values.firstName;
        data.surname = values.surname;
        data.phone = values.phone;

        await UpdatePersonDetails({
          'id': data.id,
          'userId': getJwtTokenIdentifier(),
          'firstName': data.firstName,
          'surname': data.surname,
          'phone': data.phone,
          'addresses': data.addresses,
          'companies': data.companies
        })
          .then(({ data }) => {
            setError(false);
            setMessages([data]);
            setLoading(false);
          })
          .catch((error) => {
            const response = error.response;
            SendError(error);
            setError(true);
            const errorMessage = response.data.message;
            if (errorMessage)
              setMessages([errorMessage]);

            if (response.data.errors) {
              let errorList: string[] = [];
              for (var key in response.data.errors) {
                if (response.data.errors.hasOwnProperty(key))
                  errorList.push(response.data.errors[key]);
              }
              setMessages(errorList);
            }

            setLoading(false);
          })
      }}>
     {(formik: any) => {
      return (
        <Form id="AccountDetailsForm">
          <button sx={styles.backButton} onClick={() => props.setShowEditUserModal(false)}>Back</button>
          <h1 sx={styles.header}>Update User</h1>
          <hr className="bar-indent-center" />
          <div sx={styles.form.details} className="row">
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="firstName" sx={styles.form.label}>First Name</label>
                <Field name="firstName" sx={styles.form.input} id="firstName" className="form-control" />
                <ErrorMessage name="firstName" component="div" sx={styles.input.error} />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="surname" sx={styles.form.label}>Surname</label>
                <Field name="surname" sx={styles.form.input} id="surname" className="form-control" />
                <ErrorMessage name="surname" component="div" sx={styles.input.error} />
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label htmlFor="company" sx={styles.form.label}>Company Name</label>
                <Field name="company" id="company" sx={styles.form.input} className="form-control" placeholder="Optional" />
              </div>
            </div>

            {/* <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label htmlFor="country" sx={styles.form.label}>Country</label>
                <Field name="country" id="country" sx={styles.form.input} className="form-control" value="United Kingdom (UK)" readOnly style={{ background: '#F9F9F9' }} />
              </div>
            </div> */}

            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label htmlFor="country" sx={styles.form.label}>Country</label>
                <Select name="country" id="country"
                  value={getCountryValues()}
                  classNamePrefix="react-select"
                  options={countryOptions(false)}
                  sx={styles.form.select}
                  onChange={x => countryChange(x.label, formik)}
                />
                <ErrorMessage name="country" component="div" sx={styles.input.error} />
              </div>
            </div>

            <div className="col-lg-12 col-md-6">
              <div className="form-group">
                <label htmlFor="address" sx={styles.form.label}>Address</label>
                <Field name="address" id="address" sx={styles.form.input} className="form-control" placeholder="House number and street name" />
                <ErrorMessage name="address" component="div" sx={styles.input.error} />
              </div>
            </div>

            <div className="col-lg-12 col-md-6">
              <div className="form-group">
                <label htmlFor="townCity" sx={styles.form.label}>Town / City</label>
                <Field name="townCity" id="townCity" sx={styles.form.input} className="form-control" />
                <ErrorMessage name="townCity" component="div" sx={styles.input.error} />
              </div>
            </div>

            <div className="col-lg-12 col-md-6">
              <div className="form-group">
                <label htmlFor="county" sx={styles.form.label}>County</label>
                <Field name="county" id="county" sx={styles.form.input} className="form-control" />
                <ErrorMessage name="county" component="div" sx={styles.input.error} />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="postalCode" sx={styles.form.label}>Postcode</label>
                <Field name="postalCode" id="postalCode" sx={styles.form.input} className="form-control" />
                <ErrorMessage name="postalCode" component="div" sx={styles.input.error} />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="phone" sx={styles.form.label}>Phone</label>
                <Field name="phone" id="phone" className="form-control" sx={styles.form.input} />
                <ErrorMessage name="phone" component="div" sx={styles.input.error} />
              </div>
            </div>
          </div>
          <div>
            <p sx={styles.privacyInfo}>The users personal data will be used to process their orders, and
              support their experience throughout the customer
              website, and for other purposes described in our  <span>
                <Link to="/terms-and-conditions/"> Privacy Policy </Link>
              </span>.
            </p>

            {messages.length > 0 && error && (
              <div sx={styles.error}>
                <ul sx={messages.length > 1 ? styles.withBullets : styles.withoutBullets}>
                  {messages.map((message: string) => (
                    <li sx={styles.error.fail}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            {messages.length > 0 && !error && (
              <div sx={styles.error}>
                <ul sx={messages.length > 1 ? styles.withBullets : styles.withoutBullets}>
                  {messages.map((message: string) => (
                    <li sx={styles.error.success}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <Button sx={styles.button}
              id="submitUserDetails"
              type="submit"
              disabled={loading}
            >
              {!loading && <span>Update Details</span>}

              {loading && (
                <Spinner animation="border" role="status">
                  <span className="sr-only"></span>
                </Spinner>
              )}
            </Button>
          </div>
        </Form>
         )
        }}
    </Formik >
  )
}

const DeleteAccountForm = ({ props }: any) => {
  const validationSchema = Yup.object().shape({
    accountDeletionTerms: Yup.boolean()
      .oneOf([true], "Please acknowledge the above in order to progress")
  })

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteAccountModal = () => setShowDeleteModal(false);

  const [accountDeleted, setAccountDeleted] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDeleteAccount = async () => {
    setDeleting(true);
    const result = await DeleteAccount(props.id);
    if (result.status == 200) {
      setAccountDeleted(true);
    }
    setDeleting(false);
  }

  return (
    <Formik
      initialValues={{
        accountDeletionTerms: false
      }}
      validationSchema={validationSchema}
      onSubmit={async () => {
        setShowDeleteModal(true);
      }}>
      {() => (
        <Form id="DeleteAccountForm">
          <ul sx={styles.terms}>
            <li>
              <Field type="checkbox" name="accountDeletionTerms" />
            </li>
            <li sx={styles.terms.message}> I am requesting for any personal data to be erased as soon as possible, in accordance with GDPR and the customer websites
              <span>
                <Link to="/terms-and-conditions/"> Terms and Conditions </Link>
                <ErrorMessage
                  sx={styles.input.error}
                  name="accountDeletionTerms"
                  component="div"
                />
              </span>
            </li>
          </ul>

          <Button sx={styles.button} style={{ background: '#dc3545' }}
            id="deleteAccountButton"
            type="submit">
            Delete Account
          </Button>

          <Modal
            show={showDeleteModal}
            onHide={handleCloseDeleteAccountModal}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header>
              <Modal.Title>
                {!accountDeleted ? (
                  <span>What this means</span>
                ) : (
                  <span>Account Deletion Successful</span>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!accountDeleted ? (
                <React.Fragment>
                  <b>Account deletion is irreversible:</b> This account and data will be permanently deleted.
                </React.Fragment>
              ) : (
                <p>This account has been scheduled for deletion, this is an automatic process and can not be undone.</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              {!accountDeleted ? (
                <React.Fragment>
                  <Button sx={styles.button}
                    style={{ background: '#dc3545' }}
                    onClick={(x) => handleDeleteAccount()}>

                    {!deleting ? (
                      <Text>I understand, delete this account</Text>
                    ) : (
                      <span sx={styles.buttonSpinner}>
                        <Spinner animation="border" role="status">
                          <span className="sr-only"></span>
                        </Spinner>
                      </span>
                    )}
                  </Button>{' '}

                  {!deleting && (
                    <React.Fragment>
                      <hr className="bar-indent-center" />
                      <Button sx={styles.button} onClick={handleCloseDeleteAccountModal}>Keep Account</Button>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <button sx={styles.backButton}
                    style={{ width: '100%' }}
                    onClick={() => props.setShowEditUserModal(false)}>
                    Back to user list
                  </button>
                </React.Fragment>
              )}
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

const styles = {
  container: {
    paddingLeft: '0!important',
    paddingRight: '0!important'
  },
  wrapper: {
    border: '1px solid #e4e4e4',
    boxShadow: '0 2px 45px 0 rgb(0 0 0 / 6%)',
    transition: 'background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s',
    padding: '30px 50px',
    borderRadius: '6px',
    background: 'white',
    maxWidth: '1050px',
    margin: '30px auto',
    position: 'relative',
    '@media only screen and (max-width: 536px)': {
      padding: '30px 10px',
    },
  },
  header: {
    textAlign: 'center'
  },
  additonal: {
    fontSize: '32px',
    fontWeight: '600'
  },
  button: {
    fontSize: '14px',
    color: 'white',
    fontWeight: '600',
    padding: '12.5px 25px',
    borderRadius: '6px',
    background: '#00d4c5',
    width: '100%',
    textTransform: 'uppercase'
  },
  backButton: {
    fontSize: '14px',
    color: 'white',
    fontWeight: '600',
    padding: '12.5px 25px',
    borderRadius: '6px',
    background: '#00d4c5',
    textTransform: 'uppercase',
    wisth: '100px',
    border: '#00d4c5'
  },
  buttonSpinner: {
    textAlign: "center",
    display: "block",
    ".spinner-border": {
      color: "white",
    },
  },
  terms: {
    padding: 0,
    display: 'flex',
    float: 'left',
    listStyle: 'none',
    a: {
      textDecoration: 'none',
      fontWeight: '600',
    },
    input: {
      height: '15px',
      width: '15px',
    },
    message: {
      fontSize: '13px',
      margin: '-2px 0 0 10px'
    },
  },
  input: {
    error: {
      fontSize: '13px',
      paddingBottom: '5px',
      color: '#dc3545'
    },
  },
  privacyInfo: {
    marginTop: 20,
    fontSize: '13px!important',
    a: {
      textDecoration: 'none',
      fontWeight: '600',
    },
    paddingBottom: 20
  },
  form: {
    details: {
      marginTop: 30
    },
    label: {
      fontWeight: '600',
      textTransform: 'uppercase',
      marginBottom: '0.5em',
      color: '#2F3E57'
    },
    input: {
      width: '100%',
      fontSize: '14px',
      fontWight: '400',
      borderRadius: 6,
      padding: '10px',
      opacity: '.7',
      marginBottom: '10px',
      borderWidth: '1px',
      borderStyle: 'solid',
      boxShadow: 'none',
      transition: 'border-color 0.2s ease',
      borderColor: '#E4E4E4',
      '&:focus': {
        outline: 'none',
        boxShadow: 'none',
        borderColor: 'darkgray',
      },
      error: {
        fontSize: '13px',
        marginTop: '-5px',
        paddingBottom: '5px',
        color: '#dc3545'
      }
    }
  },
  closeDeleteModal: {
    position: 'absolute',
    right: '15px',
    cursor: 'pointer',
    fontSize: '14px',
    color: 'white',
    fontWeight: '600',
    padding: '12.5px 25px',
    borderRadius: '2em',
    background: '#00d4c5',
    width: '100%',
    textTransform: 'uppercase'
  },
  error: {
    textAlign: 'center',
    fontSize: '16px',
    fail: {
      color: '#dc3545'
    },
    success: {
      color: '#6da373'
    }
  },
  withBullets: {
    display: 'inline-block',
    textAlign: 'left',
    listStyleType: 'disc'
  },
  withoutBullets: {
    listStyleType: 'none'
  }
};

export default UpdateUser
