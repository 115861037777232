/** @jsx jsx */
import { useEffect, useState } from "react";
import "../../css/account.css";
import { Button, jsx } from "theme-ui";
import {
  validateJwtToken,
  getJwtTokenRoles,
} from "../../services/auth-service";
import "react-accessible-accordion/dist/fancy-example.css";
import Spinner from "react-bootstrap/Spinner";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { RegisterAdmin } from "../../services/user-service";
import PasswordStrengthBar from 'react-password-strength-bar'

const CreateUser = (props: any) => {
  if (!validateJwtToken()) return null;
  if (!getJwtTokenRoles().includes("Admin")) return null;
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(true);

  const [sendEmails, setSendEmails] = useState(false);
  const [viewOrders, setViewOrders] = useState(false);
  const [viewUsers, setViewUsers] = useState(false);
  const [viewReports, setViewReports] = useState(false);
  const [fullAdminAccess, setFullAdminAcess] = useState(false);
  const [password, setPassword] = useState('')

  const errorMessage = "Something went wrong, please try again.";

  const CreateUserSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    secondName: Yup.string().required("Surname is required"),
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required")
  });

  const passwordStrengthBarColors = ['#dc3545', '#f6b44d', '#E56D3B', '#685f52', '#4A4A49'];
  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  }

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div className="content" sx={styles.content}>
      <Formik
         initialValues={{
          firstName: "",
          secondName: "",
          email: "",
          password: ""
        }}
        validationSchema={CreateUserSchema}
        onSubmit={async (values: any, { setSubmitting }: any) => {
          setLoading(true);

          const data = {
            FirstName: values.firstName,
            Surname: values.secondName,
            Email: values.email,
            Password: values.password,
            UserPermissions: [
              sendEmails ? 1 : 0,
              viewOrders ? 2 : 0,
              viewUsers ? 3 : 0,
              viewReports ? 4 : 0,
              fullAdminAccess ? 5 : 0,
            ]
          }

          await RegisterAdmin(data).then((result: React.SetStateAction<string>) => {

            if (result.status === 200) {
              setMessage("Successfully added new user");
            }

            setLoading(false);
            setSubmitting(false);
          })
          .catch((response) => {
            setMessage(response);
            setLoading(false);
            setSubmitting(false);
          })
        }}
      >
        {({ isSubmitting }) => (
          <Form id="CreateUserForm">
            <div className="post-form">
              <div className="row" sx={styles.form.details}>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="first_name_" sx={styles.form.label}>
                      First Name
                    </label>
                    <Field
                      name="firstName"
                      id="first_name_"
                      className="form-control"
                      sx={styles.form.input}
                      placeholder="Ava"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      sx={styles.input.error}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="secondName" sx={styles.form.label}>
                      Surname
                    </label>
                    <Field
                      name="secondName"
                      id="surname"
                      type="text"
                      className="form-control"
                      sx={styles.form.input}
                      placeholder="Smith"
                    />
                    <ErrorMessage
                      name="secondName"
                      component="div"
                      sx={styles.input.error}
                    />
                  </div>
                </div>

                <div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="email" sx={styles.form.label}>
                        Email Address
                      </label>
                      <Field
                        name="email"
                        className="form-control"
                        sx={styles.form.input}
                        placeholder="ava.smith@placentaplus.com"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        sx={styles.input.error}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="email" sx={styles.form.label}>
                        Password
                      </label>
                      <Field
                        name="password"
                        className="form-control"
                        sx={styles.form.input}
                        placeholder="Create a strong password"
                        onKeyUp={handlePasswordChange}
                      />
                       <PasswordStrengthBar password={password} barColors={passwordStrengthBarColors} />
                      <ErrorMessage
                        name="password"
                        component="div"
                        sx={styles.input.error}
                      />
                    </div>
                  </div>

                  <div className="checkbox">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        sx={styles.form.checkbox}
                        onChange={(x) => setSendEmails(x.target.checked)}
                      />
                      <label htmlFor="checkbox" sx={styles.form.label}>
                        Send Emails
                      </label>

                      <ErrorMessage
                        name="body"
                        component="div"
                        sx={styles.input.error}
                      />
                    </div>
                  </div>
                  <div className="checkbox">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        sx={styles.form.checkbox}
                        onChange={(x) => setViewOrders(x.target.checked)}
                      />
                      <label htmlFor="checkbox" sx={styles.form.label}>
                        View orders
                      </label>

                      <ErrorMessage
                        name="body"
                        component="div"
                        sx={styles.input.error}
                      />
                    </div>
                  </div>
                  <div className="checkbox">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        sx={styles.form.checkbox}
                        onChange={(x) => setViewUsers(x.target.checked)}
                      />
                      <label htmlFor="checkbox" sx={styles.form.label}>
                        View Users
                      </label>

                      <ErrorMessage
                        name="body"
                        component="div"
                        sx={styles.input.error}
                      />
                    </div>
                  </div>
                  <div className="checkbox">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        sx={styles.form.checkbox}
                        onChange={(x) => setViewReports(x.target.checked)}
                      />
                      <label htmlFor="checkbox" sx={styles.form.label}>
                        View Reports
                      </label>

                      <ErrorMessage
                        name="body"
                        component="div"
                        sx={styles.input.error}
                      />
                    </div>
                  </div>
                  <div className="checkbox">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        sx={styles.form.checkbox}
                        onChange={(x) => setFullAdminAcess(x.target.checked)}
                      />
                      <label htmlFor="checkbox" sx={styles.form.label}>
                       Full admin access
                      </label>

                      <ErrorMessage
                        name="body"
                        component="div"
                        sx={styles.input.error}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {message !== "" && (
                  <p
                    className={!error ? "text-success" : "text-danger"}
                    sx={styles.message}
                  >
                    {message}
                  </p>
                )}
                <Button
                  id="save"
                  sx={styles.button}
                  type="submit"
                  disabled={isSubmitting}
                  style={{ margin: "0!important" }}
                >
                  {!saving && <span>Save</span>}

                  {saving && (
                    <Spinner animation="border" role="status">
                      <span className="sr-only"></span>
                    </Spinner>
                  )}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const styles = {
  container: {
    "@media only screen and (max-width: 768px)": {
      paddingLeft: "10px!important",
      paddingRight: "10px!important",
    },
  },
  wrapper: {
    border: "1px solid #e4e4e4",
    boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
    transition:
      "background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
    padding: "30px 40px 30px 40px",
    borderRadius: "6px",
    background: "white",
    maxWidth: "1200px",
    margin: "50px auto",
    position: "relative",
    "@media only screen and (max-width: 768px)": {
      padding: "30px 10px",
      margin: "30px auto",
    },
  },
  tableWrapper: {
    "@media only screen and (max-width: 1206px)": {
      overflowX: "scroll",
    },
    maxWidth: "1300px",
  },

  button: {
    fontSize: "14px",
    color: "white",
    fontWeight: "600",
    marginTop: "2rem",
    padding: "12.5px 25px",
    borderRadius: "10px",
    background: "#00d4c5",
    width: "100%",
    textTransform: "uppercase",

    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      backgroundColor: "#E6E6E6",
      borderColor: "#E6E6E6",
      color: "#878788",
      fontWeight: 500,
    },
    ":hover": {
      opacity: ".9",
    },
  },
  header: {
    textAlign: "center",
  },
  subHeader: {
    padding: "20px",
    textAlign: "center",
  },
  bar: {
    "::before": {
      "@media only screen and (max-width: 768px)": {
        position: "unset",
        margin: "0 auto",
      },
    },
  },
  spinner: {
    textAlign: "center",
    padding: "20px",
    display: "block",
    ".spinner-border": {
      height: "4em",
      width: "4em",
      color: "#00d4c5",
    },
  },
  form: {
    details: {
      marginBottom: 30,
    },
    label: {
      fontWeight: "600",
      marginBottom: "0.5em",
      color: "#2F3E57",
    },
    checkbox: {
      padding: "1em",
      marginTop: "1em",
      marginRight: "0.5em",
      "@media only screen and (max-width: 768px)": {
        marginTop: "1em",
      },
    },
    input: {
      color: "#2F3E57",
      width: "100%",
      fontSize: "1rem",
      fontWeight: "400",
      borderRadius: 6,
      padding: "10px",
      opacity: ".7",
      marginBottom: "10px",
      borderWidth: "1px",
      borderStyle: "solid",
      boxShadow: "none",
      transition: "border-color 0.2s ease",
      borderColor: "#E4E4E4",
      "&:focus": {
        outline: "none",
        boxShadow: "none",
        borderColor: "darkgray",
      },
      error: {
        fontSize: "13px",
        marginTop: "-5px",
        paddingBottom: "5px",
        color: "#dc3545",
      },
    },
  },
  buttonDelete: {
    fontSize: "14px",
    background: "#dc3545",
    fontWeight: "600",
    marginTop: "16px",
    padding: "12.5px 25px",
    borderRadius: "10px",

    width: "100%",
    textTransform: "uppercase",
  },
  heading: {
    fontWeight: 500,
  },
  item: {
    cursor: "pointer",
    "list-style-type": "none",
    textAlign: "left",
    width: "100%",
    border: "3px solid #e4e4e4",
    margin: "6px",

    ":hover": {
      opacity: ".9",
    },
    ":active": {
      backgroundColour: "#8b0000",
    },
  },
  content: {
    backgroundColor: "#f5f5f5",
    color: "black",
    overflow: "hidden",
    padding: "20px!important",
    borderRadius: "5px",
    "&.show": {
      display: "none",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "18px",
    paddingRight: "18px",
    backgroundColor: "#eeee",
    color: "black",
    textTransform: "uppercase",
  },
  icon: {
    paddingRight: "18px",
  },
  input: {
    color: "#2F3E57",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    borderRadius: 6,
    padding: "10px",
    opacity: ".7",
    marginBottom: "10px",
    borderWidth: "1px",
    borderStyle: "solid",
    boxShadow: "none",
    transition: "border-color 0.2s ease",
    borderColor: "#E4E4E4",
    "&:focus": {
      outline: "none",
      boxShadow: "none",
      borderColor: "darkgray",
    },
    error: {
      fontSize: "13px",
      marginTop: "-5px",
      paddingBottom: "5px",
      color: "#dc3545",
    },
  },
  message: {
    float: "unset!important",
    fontSize: "16px",
    textAlign: "center",
  },
};

export default CreateUser;
