import React, { useState } from 'react'

const Pagination = ({ nPages, currentPage, setCurrentPage, handleSelectedPagination, start, end }) => {



  const pageNumbers = [...Array(nPages + 1).keys()].slice(start, end);

  const selectedPage = (pgNumber: any) => {
    setCurrentPage(pgNumber);
    handleSelectedPagination(pgNumber - 1);
  }

  const nextSet = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
    handleSelectedPagination(null, end);
  }

  const nextPage = () => {
    if(currentPage !== nPages) setCurrentPage(currentPage + 1);
    handleSelectedPagination(currentPage);
}

  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
    handleSelectedPagination(currentPage - 2);
  }

  return (
    <nav>
      <ul className='pagination justify-content-center'>
        {currentPage > 1 && (
          <li className="page-item">
            <a className="page-link"
              onClick={prevPage}
              href='#'>

              {"<"}
            </a>
          </li>
        )}
        {pageNumbers.map(pgNumber => (
          <li key={pgNumber}
            className={`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

            <a onClick={() => selectedPage(pgNumber)}
              className='page-link'
              href='#'>

              {pgNumber}
            </a>
          </li>

        ))}
        {nPages > end && (
          <React.Fragment>
            <li className="page-item">
              <a className="page-link"
                onClick={nextPage}
                href='#'>

                {">"}
              </a>
            </li>
            <li className="page-item">
              <a className="page-link"
                onClick={nextSet}
                href='#'>

                ..{end}
              </a>
            </li>
          </React.Fragment>
        )}
      </ul>
    </nav>
  )
}


export default Pagination