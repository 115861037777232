/** @jsx jsx */
import React, { useEffect, useState, useContext } from "react";
import { Container, jsx, Button, Text } from "theme-ui";
import "../../css/account.css";
import { Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { IoIosClose } from "react-icons/io";
import { GoSearch } from "react-icons/go";
import PrimaryLayout from "../../components/layout/primary/primary";
import AccountTopMenu from "../../components/top-menus/account/account-top-menu";
import { validateJwtToken, getJwtTokenRoles } from "../../services/auth-service";
import Pagination from "../../components/pagination/pagination";
import Select from 'react-select';
import { GetList } from "../../services/user-service";
import { GetAllEmailTemplates, sendEmail } from "../../services/email-service";
import { AiOutlineEdit } from "react-icons/ai";
import  CreateUser  from "../../components/users/create-user"
import  UpdateUser  from "../../components/users/update-users"
import { navigate } from "gatsby";

const UserManagementPage: React.FunctionComponent = ({ }: any) => {
  if (!validateJwtToken()) return null;
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPages, setNPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentRecords, setCurrentRecords] = useState(0);
  const [emailTemplates, setEmailTemplates] = useState();
  const [sendingEmail, setSendingEmail] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(11);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [sendEmails, setSendEmails] = useState(false);
  const [userId, setUserId] = useState();

  useEffect(() => {
    setLoading(true);

    const roles = getJwtTokenRoles();
    if (!roles.includes("FullAccess") && !roles.includes("ViewUser")) {
      navigate('/401');
    }
    else {
      setSendEmails(roles.includes("SendEmails") || roles.includes("FullAccess"));

      const fetchData = async () => {
        await getUsersAsync();

        const templates = await GetAllEmailTemplates();
        const mappedTemplates: any[] = [];
        templates.data.map((x: any) => {
          mappedTemplates.push({
            value: x.id, label: x.name
          })
        });
        setEmailTemplates(mappedTemplates);
        setLoading(false);
      };
      fetchData();
    }
  }, []);

  const getUsersAsync = async (page?: any) => {
    let pageNumber = page;
    if (currentPage == 1 && page == undefined) {
      pageNumber = 0;
    }

    await GetList(pageNumber != undefined ? pageNumber : currentPage, recordsPerPage, searchTerm).then((result) => {
      if (result.status === 200) {
        let data = Object.values(result.data)[0];
        let total = parseInt(Object.keys(result.data)[0]);

        setTotalRecords(Number.isNaN(total) ? 0 : total);
        setUsers(data);

        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
        const pages = Math.ceil(total / recordsPerPage);
        setCurrentRecords(currentRecords);
        setNPages(pages);

        setCheckedState(new Array(data.length).fill(false));

      } else {
        setError(true);
        setMessage(result);
      }
    });
  };

  const handleSelectedPagination = async (currentPage?: any, page?: any) => {
    setLoading(true);

    if (page !== undefined && page > 1) {
      await getUsersAsync(page);
      setCurrentPage(page);
      setStart(page);
      setEnd(page + 10);
    }
    else {
      await getUsersAsync(currentPage);
      setStart(currentPage);
      setEnd(currentPage + 10);
    }
    setLoading(false);
  }

  const handleOnChange = (event: any, position: any, user: any) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);

    let users = selectedUsers;
    event.target.checked
      ? users.push(user)
      : users = users.filter(x => x.id !== user.id);

    setSelectedUsers(users);
  };

  const handleEditUser = (user: any) => {
    setShowEditUserModal(true);
    setUserId(user.id);
  };

  const selectAllCheckboxes = (e: any) => {
    if (e === true) {
      setCheckedState(checkedState.map(() => true));
    } else {
      setCheckedState(checkedState.map((item) => !item));
    }
  };

  const handleStatus = (status: string) => {
    status = status.toLowerCase();

    //klarna order statuses
    if (status === "active") {
      return styles.status.succeeded;
    }
    else if (status == "disabled") {
      return styles.status.pending;
    }
    //stripe order statuses
    else if (status == "Deletion Scheduled") {
      return styles.status.pending;
    }
    else {
      return styles.status.pending;
    }
  };

  const handleShowConfirmEmailModal = (value: any) => {
    setMessage("");
    setShowConfirmEmailModal(value);
  }

  const handleEmailTemplate = (selected: any) => {
    setSelectedTemplate(selected);
    handleShowConfirmEmailModal(true);
  }

  const handleSendEmail = async () => {
    setSendingEmail(true);

    const data = selectedUsers.map((user: any) => {
        return {
          UserId: +user.id,
          EmailTemplateId: +selectedTemplate.value
        }
    });
    const result = await sendEmail(data);    
    if (result.status === 200) {
      setMessage(`${data.length > 1 ? "Emails" : "Email"} sent successfully`);
    }
    setSendingEmail(false);
  }

  const handleSearch = async () => {
    setLoading(true);
    await getUsersAsync(0);
    setLoading(false);
  }

  const TableCountLabel = () => {    
    const text = loading ? "Fetching" : "Showing";    

    return (
      <React.Fragment>
        {text} {" "}
        {(currentPage == 1 ? 1 : (currentPage - 1) * 10 + 1)}
        {" "}-{" "}
        {currentPage == 1 ? 10 : currentPage * 10} of {totalRecords}
      </React.Fragment>
    );
  }

  return (
    <PrimaryLayout topMenuChild={AccountTopMenu}>

      {!showEditUserModal ? (
        <Container py="40px" sx={styles.container}>
          <div className="main-wrapper">
            <div className="header" sx={styles.header}>
              <h1>User Management</h1>
              <hr className="bar-indent-center" />
            </div>
            <div className="row">
              <div className="col-md-5 offset-md-7">
                <div className="search-wrapper" sx={styles.input.searchWrapper}>
                  <GoSearch sx={styles.input.searchIcon} className="hidden" />{" "}
                  <input
                    className="search"
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Search Users..."
                    sx={styles.input.searchBar}
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                    }}
                  ></input>
                  <Button sx={styles.input.searchButton}
                    onClick={() => handleSearch()}>
                    {!updating && <span></span>}
                    {updating && (
                      <Spinner animation="border" role="status">
                        <span className="sr-only"></span>
                      </Spinner>
                    )}
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-7">
            <p sx={styles.tableCountLabel}>
              <TableCountLabel />
            </p>
          </div>

          <div className="wrapper" sx={styles.tableWrapper}>
            {!loading ? (
              <React.Fragment>
                <section className="action-area">
                  <div className="sendEmailModal">
                    <div className="modal" id="sendEmailModal">
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content modal-dialog modal-xl">
                          <Modal
                            show={showConfirmEmailModal}
                            onHide={() => handleShowConfirmEmailModal(false)}
                            keyboard={true}
                            centered
                          >
                            <Modal.Header>
                              <Modal.Title>
                                <h3 sx={styles.modal.header}>Confirm</h3>
                                <IoIosClose
                                  sx={styles.modal.close}
                                  onClick={() => handleShowConfirmEmailModal(false)}
                                />
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body sx={styles.modal.body}>
                              Email Template:  <b>{selectedTemplate.label}</b>
                              <br />
                              Users:
                              <div sx={styles.modal.description}>
                                <ul>
                                  {checkedState.map((item, index) =>
                                    item && (
                                      <li>{users[index].person.firstName} {users[index].person.surname}</li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </Modal.Body>

                            <Modal.Footer>
                              <Button
                                sx={styles.buttonConfirm}
                                onClick={() => handleSendEmail()}>
                                {!sendingEmail ? (
                                  <Text>Send</Text>
                                ) : (
                                  <span sx={styles.buttonSpinner}>
                                    <Spinner animation="border" role="status">
                                      <span className="sr-only"></span>
                                    </Spinner>
                                  </span>
                                )}
                              </Button>

                              <div className="col-lg-12 col-md-12">
                                {message && (
                                  <p className={"text-center  " + (error ? 'text-danger' : 'text-success')}>
                                    {message}
                                  </p>
                                )}
                              </div>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <Button sx={styles.userCreateButton}
                  onClick={() => setShowUserModal(true)}>
                  {!updating && <span></span>}
                  {updating && (
                    <Spinner animation="border" role="status">
                      <span className="sr-only"></span>
                    </Spinner>
                  )}
                  Create Admin User
                </Button>
                <table
                  className="table"
                  sx={styles.table}
                  role="table"
                  id="usersTable"
                >
                  <thead role="rowgroup" id="usersTable">
                    <tr role="row" id="usersTable">
                      <th>
                        <input
                          className="select-all"
                          type="checkbox"
                          id="selectAll"
                          name="select"
                          value="yes"
                          onChange={(e) => selectAllCheckboxes(e.target.checked)}
                        />
                      </th>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Role</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup" id="usersTable">
                    {users.map((user, index) => (
                      <tr role="row">
                        <td role="cell">
                          <input
                            className="checkbox"
                            type="checkbox"
                            id="select"
                            name="checkboxAll"
                            value="yes"
                            checked={checkedState[index]}
                            onChange={(x) => handleOnChange(x, index, user)}
                          ></input>
                        </td>
                        <td role="cell">{user.id}</td>
                        <td role="cell">{user.person.firstName} {user.person.surname}</td>
                        <td>{user.email}</td>
                        <td className="text-center">
                          <span
                            sx={handleStatus(user.status)}>
                            {user.status}
                          </span>
                        </td>
                        <td className="text-center">
                          {user.role == "User" ? "Customer" : user.role}
                        </td>
                        <td className="text-center" sx={styles.actions}>
                          <AiOutlineEdit onClick={(x) => handleEditUser(user)} />
                        </td>
                      </tr>
                    )
                    )}
                  </tbody>
                </table>

                {sendEmails && (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3">
                        <Select
                          name="emailTemplate"
                          id="emailTemplate"
                          classNamePrefix="react-select"
                          options={emailTemplates}
                          onChange={(x) => handleEmailTemplate(x)}
                          isDisabled={!checkedState.includes(true)}
                          sx={styles.emailTemplateSelect}
                        />
                      </div>
                      <div className="col-md-9">
                        <p style={{ float: "right" }}>
                          <TableCountLabel />
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-12" style={{ marginTop: '20px' }}>
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    handleSelectedPagination={handleSelectedPagination}
                    start={start}
                    end={end}
                  />
                </div>
              </React.Fragment>
            ) : (
              <span sx={styles.spinner}>
                <Spinner animation="border" role="status">
                  <span className="sr-only"></span>
                </Spinner>
              </span>
            )}
          </div>
        </Container>
      ) : (
        <UpdateUser setShowEditUserModal={setShowEditUserModal} id={userId}/>
      )}

      <Modal
        show={showUserModal}
        onHide={() => setShowUserModal(false)}
        keyboard={true}
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h3 sx={styles.modal.header}>New Admin User</h3>
            <IoIosClose
              sx={styles.modal.close}
              onClick={() => setShowUserModal(false)}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body sx={styles.modal.body}>
         <CreateUser />
        </Modal.Body>
      </Modal>
    </PrimaryLayout>
  );
};

const styles = {
  actions: {
    svg: {
      fontSize: '23px'
    }
  },
  tableCountLabel: {
    marginTop: '-27px',
    "@media only screen and (max-width: 768px)": {
      display: 'none'
    },
  },
  emailTemplateSelect: {
    fontSize: '13px'
  },
  spinner: {
    textAlign: "center",
    padding: "20px",
    display: "block",
    ".spinner-border": {
      height: "4em",
      width: "4em",
      color: "#00d4c5",
    },
  },
  buttonSpinner: {
    textAlign: "center",
    display: "block",
    ".spinner-border": {
      color: "white",
    },
  },
  right: {
    float: 'right'
  },
  status: {
    pending: {
      background: "#f8d7da",
      borderColor: "#f5c6cb",
      color: "#721c24",
      padding: "5px",
      borderRadius: "4px",
    },
    succeeded: {
      background: "#d4edda",
      borderColor: "##c3e6cb",
      color: "#155724",
      padding: "5px",
      borderRadius: "4px",
    },
  },
  container: {
    "@media only screen and (max-width: 768px)": {
      paddingLeft: "10px!important",
      paddingRight: "10px!important",
    },
    wrapper: {
      border: "1px solid #e4e4e4",
      boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
      transition:
        "background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
      borderRadius: "6px",
      background: "white",
      position: "relative",
      margin: "0 auto",
      "@media only screen and (max-width: 768px)": {
        padding: 20,
      },
    },

    tableWrapper: {
      "@media only screen and (max-width: 1206px)": {
        overflowX: "scroll",
      },
      maxWidth: "1200px",
    },

    footer: {
      display: "unset",
    },

    products: {
      paddingLeft: "10px",
      item: {
        width: "max-content",
      },
    },
    error: {
      margin: "0 auto",
      fontSize: "14px",
    },
    headerWrapper: {
      padding: "40px 30px 0 30px",
      "@media only screen and (max-width: 768px)": {
        padding: "30px 10px",
        margin: "30px auto",
      },
    },
    header: {
      textAlign: "center",
    },
    modal: {
      description: {
        maxHeight: "200px",
        overflowY: "scroll",
      },
      close: {
        position: "absolute",
        right: "3px",
        fontSize: "40px",
        top: "5px",
        cursor: "pointer",
      },
      footer: {
        width: "100%",
        margin: "0 auto",
        display: "block",
      },
      title: {
        fontSize: "17px",
        margin: 0,
      },
    },
  },
  table: {
    marginTop: '20px',
    width: "100%",
    borderCollapse: "collapse",
  },
  checkbox: {
    width: "5%",
    wordWrap: "break-Word",
  },
  id: {
    width: "10%",
    wordWrap: "break-Word",
  },
  date: {
    width: "10%",
    wordWrap: "break-Word",
  },
  balance: {
    width: "20%",
    wordWrap: "break-Word",
  },
  header: {
    textAlign: "center",
  },
  selected: {
    border: "1px solid #e4e4e4",
    boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
    transition:
      "background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
    padding: "30px 40px 30px 40px",
    borderRadius: "6px",
    background: "white",
    maxWidth: "400px",
    marginTop: "3rem",
    margin: "40 auto",
    position: "relative",
    "@media only screen and (max-width: 768px)": {
      padding: "20px 20px",
      margin: "10px auto",
    },
  },
  selectedcontent: {
    display: "flex",
    justifyContent: "center",
  },
  item: {
    cursor: "pointer",
    "list-style-type": "none",
    textAlign: "left",
    width: "100%",
    border: "3px solid #eeee",
    margin: "6px",

    ":hover": {
      opacity: ".9",
    },
    ":active": {
      backgroundColour: "#8b0000",
    },
  },
  content: {
    backgroundColor: "white",
    color: "black",
    overflow: "hidden",
    paddingLeft: "18px",

    margin: "6px",

    "&.show": {
      display: "none",
    },
  },
  wrapper: {
    marginTop: "5rem",
    maxWidth: "600px",
    border: "1px solid #eeee",
    padding: "30px 40px 30px 40px",
    borderRadius: "6px",
    boxShadow: "0 2px 4px rgba(41, 41, 41, 0.06)",
    "@media only screen and (max-width: 768px)": {
      padding: "20px 20px",
      margin: "10px auto",
      marginTop: "2rem",
    },
  },

  sectionHeading: {
    marginBottom: "1.5rem",
  },
  buttonDanger: {
    fontSize: "14px",
    color: "white",
    fontWeight: "600",
    padding: "12.5px 25px",
    borderRadius: "10px",
    background: "#DC3545",
    width: "100%",
    textTransform: "uppercase",

    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      backgroundColor: "#DC3545",
      borderColor: "#DC3545",
      color: "#878788",
      fontWeight: 500,
    },
    ":hover": {
      opacity: ".9",
    },
  },
  modal: {
    header: {
      textAlign: "center",
    },
    close: {
      position: "absolute",
      right: "3px",
      fontSize: "40px",
      top: "5px",
      cursor: "pointer",
    },
    description: {
      maxHeight: '250px',
      overflowY: 'scroll'
    },
    title: {
      fontSize: "17px",
      margin: 0,
    },
    body: {
      lineHeight: "2.5rem",
    },
  },
  filter: {
    color: "#878788",
  },
  tableWrapper: {
    '@media only screen and (max-width: 1206px)': {
      overflowX: 'scroll'
    },
    marginTop: "20px",
    border: "1px solid #eeee",
    padding: "30px 40px 30px 40px",
    borderRadius: "6px",
    boxShadow: "0 2px 4px rgba(41, 41, 41, 0.06)",
    "@media only screen and (max-width: 768px)": {
      padding: "20px 20px",
      margin: "10px auto",
      marginTop: "2rem",
    },
  },
  button: {
    color: "white",
    padding: "5px",
    borderRadius: "4px",
    background: "rgb(35, 179, 163)",
    border: "rgb(35, 179, 163)",
    fontWeight: 500,
    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      backgroundColor: "#00D4C5",
      borderColor: "#00D4C5",
      color: "#878788",
      fontWeight: 500,
    },
    ":hover": {
      opacity: ".9",
    },
  },
  buttonConfirm: {
    fontSize: "14px",
    color: "white",
    fontWeight: "600",
    padding: "12.5px 25px",
    borderRadius: "10px",
    background: "#00D4C5",
    width: "100%",
    textTransform: "uppercase",

    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      backgroundColor: "#00D4C5",
      borderColor: "#00D4C5",
      color: "#878788",
      fontWeight: 500,
    },
    ":hover": {
      opacity: ".9",
    },
  },
  input: {
    searchWrapper: {
      display: "flex",
      alignItems: "center",
      border: "1px solid #E5E5E5",
      borderRadius: "8px",
      textIndent: '5px',
      "@media only screen and (max-width: 768px)": {
        width: "100%",
        display: "block",
      },
      float: 'right'
    },
    searchBar: {
      width: "100%",
      paddingRight: "40px",
      marginLeft: "30px",
      border: "0px",
      borderStyle: "solid",
      boxShadow: "none",
      transition: "border-color 0.2s ease",
      borderColor: "#E4E4E4",
      "&:focus": {
        outline: "none",
        boxShadow: "none",
        borderColor: "darkgray",
      },
      "@media only screen and (max-width: 768px)": {
        width: "80%",
        display: "block",
        padding: ".8rem",
        marginLeft: "24px",
      },
    },
    searchIcon: {
      position: "absolute",
      marginLeft: "8px",
      fontSize: "18px",
      "@media only screen and (max-width: 768px)": {
        fontSize: "22px",
        position: "absolute",
        marginLeft: "8px",
        marginTop: "0.8rem",
      },
    },
    searchButton: {
      backgroundColor: "#00d4c5",
      fontSize: "14px",
      fontWeight: "600",
      textTransform: "uppercase",
      borderRadius: "0 6px 6px 0",
      "@media only screen and (max-width: 768px)": {
        width: "100%",
        borderRadius: "0 0  6px 6px",
      },
    },
  },
  pagination: {
    justifyContent: "center",
    '.pagination': {
      float: 'right!important'
    }
  },
  userCreateButton: {
    backgroundColor: "#00d4c5",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "uppercase",
    borderRadius: "6px",
  },
};

export default UserManagementPage;
